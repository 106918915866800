<!--
 * @Author: daipeng
 * @Date: 2019-09-09 11:49:57
 * @LastEditors: VSCode
 * @LastEditTime: 2019-09-12 14:38:21
 * @Description:
 -->
<template>
    <div class="label-config">
        <div class="label-config-head mr10">
            <h2>漫画名称：{{comicName}}</h2><h2>漫画ID：{{comicId}}</h2>
        </div>
        <labelAE />
    </div>
</template>

<script>
import labelAE from './components/labelAE';

export default {
    components: { labelAE },
    data() {
        return {
            comicName: '',
            comicId: ''
        };
    },
    created() {
        const { comicName = '', comicId = '' } = this.$route.query;
        this.comicName = comicName;
        this.comicId = comicId;
    },
    methods: {

    }
};
</script>

<style lang="scss" scoped>
    .label-config{
        box-sizing: border-box;
        width: 100%;
        min-height: 100%;
        padding: 15px;
        background: #ffffff;
        &-head{
            display: flex;
            flex-flow: row nowrap;
            h2{
                margin-right: 20px;
                font-size: 14px;
            }
        }
    }
</style>
